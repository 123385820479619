<template>
  <div class="buildings">
    <MobileTable
      v-model:selection="store.state.building.selectedBuildings"
      :label="$route.name"
      :columns="building.columns"
      :data="store.state.building.buildings"
    />
    <DesktopTable
      v-model:selection="store.state.building.selectedBuildings"
      :label="$route.name"
      :columns="building.columns"
      :data="store.state.building.buildings"
    />
    <Actions :items="building.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, roles, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/complejos/nuevo");
    };
    const onModify = () => {
      if (store.state.building.selectedBuildings.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/complejos/modificar");
      }
    };
    const onRemove = async () => {
      if (store.state.building.selectedBuildings.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.buildingActions.delete,
          store.state.building.selectedBuildings[0].id
        );
        if (response.ok) {
          store.commit("setSelectedBuildings", []);
          await store.dispatch(
            actions.buildingActions.findAll,
            store.state.auth.user.company
          );
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };
    const onAssociate = () => {
      if (store.state.building.selectedBuildings.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        router.push("/complejos/torres/asociar");
      }
    };

    const building = reactive({
      columns: [
        {
          field: "company",
          header: "Administradora",
          sort: true,
        },
        {
          field: "name",
          header: "Complejo",
          sort: true,
        },
        {
          field: "identity",
          header: "Rut / CI",
          sort: true,
        },
        {
          field: "address",
          header: "Dirección",
        },
        {
          field: "state",
          header: "Departamento",
          sort: true,
        },
        {
          field: "city",
          header: "Ciudad",
          sort: true,
        },
        {
          field: "email",
          header: "Email",
        },
        {
          field: "phone",
          header: "Teléfono",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/nuevo"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/modificar"),
        },
        {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/modificar"),
        },
        {
          name: "Torres",
          action: onAssociate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/torres/asociar"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedBuildings", []);
      if (store.state.auth.role.name === roles.master) {
        const finded = store.state.general.breadcrumbs.find(
          (item) => item.to === route.path
        );
        if (!finded)
          store.commit("addBreadcrumb", { label: route.name, to: route.path });
      } else {
        building.columns.shift();
        store.commit("setBreadcrumbs", [
          { label: "Inicio", to: "/home" },
          { label: route.name, to: route.path },
        ]);
      }
      await store.dispatch(
        actions.buildingActions.findAll,
        store.state.auth.user.company
      );
      store.commit("setLoading", false);
    });

    return { store, building };
  },
};
</script>

<style scoped></style>
